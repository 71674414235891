import React from "react";
import "../Work/Work.scss";
function Work() {
  return (
    <div className="work">
      <img width={250} src="./images/4.png" alt="alt-image" />
      <img width={350} src="./images/maintainance.png" alt="alt-image" />
      <h3>This Page Is In Under Maintenance</h3>
    </div>
  );
}

export default Work;
